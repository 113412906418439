@mixin animationFormLogin {
    @keyframes show {
        0%,49.99% {
            opacity: 0;
            z-index: 1;
        }
        50%,100% {
            opacity: 1;
            z-index: 5;
        }
    }
}

@mixin gridContainer($numColumns:2, $minHeigthRow:auto, $alignItems:center) {
    display: grid;
	grid-template-columns: repeat($numColumns, 2fr);
	gap: 2em;
	grid-auto-rows: minmax($minHeigthRow, auto);
	align-items: $alignItems;

    @include xs{
        gap: 0.5em !important; 
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: auto !important;
        row-gap: 1em;
        margin-bottom: 1.5em;
    }

    @content;
}

@mixin fontFamily($font:'Montserrat',$weight:300){
    font-family: $font, sans-serif !important;
	font-weight: $weight !important;
}