@import '../../../5-styles/config.scss';

.containerTitles{
    margin: 1em auto;
    margin-top: 0;
    border-radius: 4em !important;
    width: fit-content !important;

    & > div{
        padding: 0 !important;
        & > div{
            padding: 0.5em !important;

            & > [class*='Connector']{
                width: 1em !important;
            }
        }
    }
}

.titleStep{
    @include lt-sm {
        display: none !important;
     }
}