@import '../../../5-styles/config.scss';

.commentsContainer{
    width: 80%;
    @include lt-sm{
        width: 100%;
    }

    & > div{
        margin-bottom: 1.5em;

        &:last-child{
            margin-bottom: 0;
        }
    }
}
.structureComment{
    display: flex;
    border-radius: 0.5em;
    box-shadow: 0px 0px 6px #CCC;
    padding: 1em;
    position: relative;

    & > span {
        margin: 0 0.7em;
        @include lt-sm{
            margin: 0 0.2em;
        }
    }

    & > div{
        flex: 1;

        & > p{            
            margin-top: 1em;
            color: rgb(146, 146, 146);
        }

        & > h3{
            margin-bottom: 0.5em;
            text-transform: capitalize;
        }
    }
}

.addComment{
    @include lt-sm{
        margin-top: 2em !important;
    }

    & [class*='inputMoons']{
        margin-top: 1em;
    }

    &>button:first-of-type {
        position: absolute;
        top:1em;
        right: 1em;

        @include lt-sm {
                top: -4em !important;
            }
        }
        
        &>button:nth-of-type(2) {
            position: absolute;
            top: 1em;
            right: 12em;
        @include lt-sm{
            top: -4em !important;
        }
    }
}

.moonsComment{
    background-color: #F2FDFF;

    & > span{
        transform: rotate(225deg) !important;
    }
}