@import './../../../../../5-styles/config.scss';

.containerStlUpload{
    margin-top: 1.5em;

    & h3 {
        margin-bottom: 1em;
    }

    & [class*='containerDrop']{
        height: 6em;
        cursor: pointer;
        @include lt-sm {
            height: 11em;
        }
    }

    & button{
        margin-top: 1.5em;
    }


}