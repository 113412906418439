.modal {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    height: 15rem;
}

.modalRoot {
    overflow: unset !important;
}
.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}