@import './../../5-styles/config.scss';
@include animationFormLogin;

.loginContainer{
	background: #deebff;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;

	.container {
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
		position: relative;
		overflow: hidden;
		width: 768px;
		max-width: 100%;
		min-height: 480px;
	}
	
}


[class*='signInContainer'] {
	left: 0;
	width: 50%;
	z-index: 2;
}

[class*='signUpContainer'] {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

[class*='rightPanelActive'] {

	[class*='signInContainer'] {
		transform: translateX(100%);
	}
	[class*='signUpContainer'] {
		transform: translateX(100%);
		opacity: 1;
		z-index: 5;
		animation: show 0.6s;
	}
	[class*='_overlayContainer_'] {
		transform: translateX(-100%);
	}
	[class*='_overlay_'] {
		transform: translateX(50%);
	}
	[class*='_overlayLeft_'] {
		transform: translateX(0);
	}
	[class*='_overlayRight_'] {
		transform: translateX(20%);
	}
}


