@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
*{
    margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
	border: none;
	outline: none;
    box-sizing: border-box;    
}

body {
    font-size: $font-size-global;
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 300;
}

.mainContainer{
	width: 100vw;
	height: 100vh;
	position: relative;
}

form.generic{
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;

	& div[class*='input'],
	& div[class*='Select']{
		margin: 0.5em 0;
	}

	& button{
		margin: 1.2em 0;
	}

	& div[class*='required'] label::after{
		content: ' *';
		color:red;
	}

	@include lt-sm {
		padding: 0 !important;
	}

}

.textCapitalize{
	text-transform: capitalize !important;
}

.MuiDataGrid-cell {
	cursor: pointer !important;
}

[data-field="actions"] {
	cursor: default !important
}
