@import './../../../5-styles/config.scss';

.gridContainer{
    @include gridContainer(4, 2em, strech){
        @include sm{
            grid-template-columns: repeat(2, 1fr);
        }
        @include md{
            grid-template-columns: repeat(3, 1fr);
        }
    };

     & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
         
        @include lt-sm {
            width: 90% !important;
            margin-bottom: 1.3em;

            &:last-child{
                margin-bottom: 0 !important;
            }
         }

        & > h3{
            font-size: 0.9em;
            margin-bottom: 0.5em;
        }

        & [class*='containerDrop']{
            cursor: pointer;
            padding: 0.7em !important;
            width: fit-content !important;
            background: #F0F6FF;
            padding-bottom: 1em !important;
            border-radius: 2em;
            box-shadow: 2px 2px 3px #ccc;            

            & img{
                // width: 150px;
                height: 150px;
            }

            & p{
                text-align: center;
            }
        }

        &:last-child{
            & > div{
                background: inherit;
    
                & span{
                    font-size: 5em;
                    margin: 0.3em 1em;
                }
        }
        }
     }
}

.imgPreview{
    margin-bottom: 1em !important;
    max-width: 100% !important;
    width: auto !important;
    max-height: 150px !important;
    height: auto !important;

    & ~ p{
        color: green;
        font-weight:bold ;
    }
}