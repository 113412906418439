@import '../../../5-styles/config.scss';

.gridContainer{
    
    @include gridContainer(2, 1em);

    a {
        color: rgba(0, 0, 255, 0.807);
      }
    & > div{
        justify-self: center;
        
        &:first-child{
            display: flex;
            flex-direction: column;
            align-items: center;
            align-self: flex-start;

            & video{
                width: 80%;
                margin-bottom: 1em;

                @include lt-sm{
                    width: 100% !important;
                }
            }

            & > a{
                font-size: 1.3em;
                font-weight: bold;
                display: flex;
                align-items: center;
                margin-bottom: 0.5rem;
                
                  &>span {
                    margin-right: 0.3em;
                  }
                }
                
                &>button {
                  font-size: 1.3em;
                  font-weight: bold;
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.5rem;
                  color: rgba(0, 0, 255, 0.807);
                  background-color: transparent;
                  cursor: pointer;

                & > span{
                    margin-right: 0.3em;
                }
            }
        }

        &:nth-child(2){
            align-self: baseline;
            & > h3{
                text-align: center;
                color: red;
                margin-bottom: 0.7em;
            }
        }
    }
}

.aligners{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.7em 0;

    & > img{
        width: 6em;
    }

    & > b{
        margin-right: 0.5em;
    } 
   
}

.doubleColumn{
    grid-column-start: 1;
    grid-column-end: 3;    
    width: 100%;

    @include lt-sm{
        grid-column-start: 1 !important;
        grid-column-end: 2 !important;    
    }
}

.priceContainer{
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include lt-sm{
        padding-top: 1.5em;
    }

    & > div{
        padding: 1.5em;
        background-color: #F0F4FF;
        border-radius: 0.5em;
        text-align: center;
        max-width: 25em;
        min-width: 21em;
        margin-top: 1em;

        @include lt-sm{
            padding: 0.5em;
            min-width: 100% !important;
        }

        & > hr{
            border-bottom: 2px solid #000;
        }

        & > p{
            margin: 0.8em 0;
            display: flex;
            justify-content: space-between;
            text-align: left;
            
            &:first-child{
                & > i{
                    // text-decoration: line-through;
                    font-style: normal;
                }
            }

            &:nth-child(4){
                font-size: 1.2em;
                @include lt-sm{
                    font-size: 1.1em;
                }
            }
        }

        & > button{
            margin-top: 1.5em;
        }
    }
}

.visorImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #e2e7ed;
    border-radius: 16px;
    font-family: 'Montserrat', sans-serif;
    padding: 1rem 0;
    margin-bottom: 1rem;

    &>img {
        max-width: 15rem;
    }

    &>p {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #e61e4d;
        text-align: center;
        padding: 0 32px 24px 32px;
    }

    &>p>span {
        color: #616161;
    }
}

.archformContainer {
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 25rem;
}

.modal {
  border-radius: 8px;
  height: 90vh;
    width: 80vw;
    padding-bottom: 1rem;
}
.modalRoot {
  overflow: unset !important;
}

.comments{
    display: flex;
    flex-direction: column;
    align-items: center;
    @include lt-sm{
        padding-top: 1.5em;        
    }

    & > h3 {
        margin-bottom: 1.5em;
    }
}

.apprDateContainer {
  padding: 1.5em;
  background-color: #F0F4FF;
  border-radius: 0.5em;
  text-align: center;
  min-width: 21em;
  // max-width: 25em;
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > p {
    margin-left: 0.5rem;
  }
}