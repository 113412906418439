@import '../../../5-styles/config.scss';

.containerPatientPlanning{
    
    & [class*='linkStep']{
        color: #fff;
        background-color: #3f51b5;
        box-shadow: none;
        margin-left: 8px;
        border-radius: 20px !important;
        padding: 0.7em 2em !important;
        position: relative;
        font-family: "Montserrat", sans-serif !important;
        font-weight: 500 !important;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        font-size: 0.875rem;
        min-width: 64px;
        box-sizing: border-box;

        @include lt-sm {
            font-size: 0.6em !important;
         }

        &:hover{
            background-color: #303f9f;
        }
    }

}