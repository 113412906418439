@import './../../../5-styles/config.scss';

.inputMoons{
    width: 100%;
    border-radius: 0;

    & > label {
        @include fontFamily;
    }

    & > div {
        border-radius: 0 !important;
    }
}