@import '../../../5-styles/config.scss';

.gridContainer{
    width: 100%;
    max-width: 100%;
    background: white;
    overflow-y: auto;
    padding: 1em;

    @include gridContainer(6, 2em);

   @include lt-lg{
      @include gridContainer(4, 2em);
   }
   @include lt-md{
      @include gridContainer(2, 2em);
   }
   @include lt-sm{
      //@include gridContainer(1, 2em);
      display: flex;
   }

   & > div > .imgButtonPreview > img {
        max-width: 100% !important;
        width: auto !important;
        max-height: 150px !important;
        height: auto !important;
        justify-self: center;
        align-self: center;
        cursor: pointer;
   }
}

.backPreview{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: calc(100% + 5px);
    overflow-y: auto;
    padding: 1.5em 0;
    padding-top: 4em;

    & > div{
        width: 100%;
        display: flex;
        align-items: center;
        min-height: 100%;
        justify-content: center;
    }

    &  img {
        width: fit-content;
        max-width: 100% !important;
        height: auto;
    }
}

.btnClose{
    position: sticky !important;
    top: 0em !important;
    left: 90% !important;
    background: white !important;
    &:hover{
        background: rgba($color: #FFF, $alpha: 0.8) !important;
    }

    @include lt-sm{
        font-size: 1em;
        padding: 5px;
    }
}

.btnBack{
    position: sticky !important;
    left: 0;
    top: calc(50% - 1em) !important;
    color: white !important;

    & [class*='material-icons']{
        font-size: 1.5em;

        @include lt-sm{
            font-size: 1em;
        }
    }
    
    &:hover{
        background: rgba($color: #FFF, $alpha: 0.2) !important;
    }

    &:disabled{
        opacity: 0.2 !important;
    }

}

.regionImagePreview{
    margin-left: -3em;
    @include lt-sm{
        margin-left: -3.5em;
        margin-right: -0.5em;
    }
}

.btnForward{
    position: sticky !important;
    right: 0;
    top: calc(50% - 1em) !important;
    color: white !important;

    & [class*='material-icons']{
        font-size: 1.5em;
        @include lt-sm{
            font-size: 1em;
        }

    }

    &:hover{
        background: rgba($color: #FFF, $alpha: 0.2) !important;
    }

    &:disabled{
        opacity: 0.2 !important;
    }
}

.regionAprove{
    $widthButton: 250px;

    position: sticky;
    width: $widthButton;
    top: 1em;
    left: calc(50% - (#{$widthButton}/2));
    z-index: 9999999;
    display: flex;
    justify-content: space-between;

    & > button{
        width: 45%;
        box-shadow: 1px 3px 4px #000;
    }
}


.imgValidationContainer{
    border-radius: 4px;
}
.imgValidationContainer [class*='cardContainer']{
    margin-top: -38px;
    @include lt-sm{
        overflow: initial;
    }
}


.heightShared{
    @include lt-sm{
        height: 70vh !important;
    }
}
.heightFull{
    @include lt-sm{
        height: 85vh !important;
    }
}

.imgButtonPreview {
    cursor: pointer;
    background-color: #F0F6FF;
    border-radius: 8px;
    padding: 8px;
}

.titleViewer {
    text-transform: capitalize;
    font-size: 1.7em;
    padding: 0.5em;
    margin-top: 0.5em;
    color: #3f50b5;
    padding-bottom: 0.3em !important;
    display: flex;
    justify-content: space-between;
    @include fontFamily($weight:600);
    @include lt-sm {
        font-size: 1.3em !important;
        text-align: center;
    }
}

.imgPreviewContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.photoLabel {
    font-size: 0.9em;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.87);
    word-break: break-word;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600 !important;
    text-align: center;
}