@import '../../../../5-styles/config.scss';

.gridContainer{
   @include lt-xl{
     @include gridContainer(4, 2em);
   }
   @include lt-lg{
      @include gridContainer(3, 2em);
   }
   @include lt-md{
      @include gridContainer(2, 2em);
   }
   @include lt-sm{
      @include gridContainer(1, 2em);
   }
   

   & > [class*='MuiFormControl']{
      @include lt-sm{
         margin-bottom: 1em !important;
      }
   }
   
   & > span{
        font-size: 1.1em;
     }
}

.header{
   grid-template-columns: repeat(2, 2fr);
   margin-bottom: 1em;
   @include lt-sm{
      grid-template-columns: repeat(1, 1fr) !important;
   }
}

.FormHeading {
   text-transform: capitalize;
}



.regionBtnBack{
   display: flex;
   flex-direction: row;
   align-items: center;
}

.regionNewPlanning{
   justify-self: flex-end;
   @include lt-sm{
      justify-self: center;
   }

}

.filePickers {
  display: flex;
  flex-direction: column;
}

.downloadButton {
  align-self: center;
}

.DateInfo {
   font-family: "Montserrat", sans-serif !important;
   color: #3f50b5;
}
.externalLink {
   display: flex;
   flex-direction: row;
   gap: 0.5rem;
   margin-bottom: 1rem;
}