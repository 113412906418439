@import '../../../5-styles/config.scss';

// .flexContainer{
//     display: flex;
//     flex-direction: row;
//     width: 100%;
    
//     & > [class*='input']{
//         flex: 1;
//     }
// }

.FormHeading {
    text-transform: capitalize;
}

.gridContainer{
    @include lt-xl{
        @include gridContainer(4, 2em);
    }
    @include lt-lg{
        @include gridContainer(3, 2em);
    }
    @include lt-md{
        @include gridContainer(2, 2em);
    }
    @include lt-sm{
        @include gridContainer(1, 2em);
    }


    & > [class*='MuiFormControl']{
        @include lt-sm{
            margin-bottom: 1em !important;
        }
    }

    & > span{
            font-size: 1.1em;
        }
    }

.header{
    grid-template-columns: repeat(2, 2fr);
    margin-bottom: 1em;
    @include lt-sm{
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

.regionBtnBack{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.regionNewPlanning{
    justify-self: flex-end;
    @include lt-sm{
        justify-self: center;
    }

}

.addressTitle {
    font-size: 1.7em;
    padding: 0.5em;
    color: #3f50b5;
    padding-bottom: 0.3em !important;
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600 !important;
    margin: 16px 0;
}

h1 {
    text-transform: capitalize;
}