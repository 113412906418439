@import './../../../../../5-styles/config.scss';

.gridContainer{
    @include gridContainer(2, 4em);
    margin-top: 1em;
    > div {
        align-self: start;

        &:first-child{
            max-height: 38vw;
            overflow: auto;

            @include lt-sm {
                max-height: 55vw !important;
                border:1px solid #CCC;
            }
        }

        &:last-child{
            @include lt-sm {
               padding-top: 2em;
            }
        }
    }
}

.containerClinic{
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;

    &:last-child{
        margin-bottom: 0 !important;
    }

    & h4{
        margin-bottom: 1em;
    }

    & img{
        width: 2em;
        margin-right: 0.5em;
    }

    & [class*='MuiCardContent']{
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }
}