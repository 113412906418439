@import './../../../../../5-styles/config.scss';

.gridContainer{
    @include gridContainer(1, 4em);

    & > form{
        align-items: flex-start !important;
        margin-top: 1.5em;
    }
    
    & > div{
        display: flex;
        flex-direction: column;
    }

    & > div:first-child{
        font-size: 1.5em;
        font-weight: bold;
        flex-direction: row;
        align-items: center;
    }
}
