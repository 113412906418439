@import './../../../../../5-styles/config.scss';

.gridContainer{
    @include gridContainer(3, 4em);
    margin: 2em 0;
    & > div{
        display: flex;
        flex-direction: column;
        align-items: center;

        & > button{
            margin-top: 1em;
        }

        & > img{
            width: 6em;
            margin-bottom: 0.5em;
        }
    }
}