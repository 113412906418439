@import './../../../5-styles/config.scss';

.container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    background: #F9F9F9;
    
    & > div.body{
        flex: 1;
    }
}

.body{
    padding: 1em;
    width: 100%;
    overflow: auto;

    @include gt-md {
        padding: 1em 3em;
    }
}