@import './../../../5-styles/config.scss';

.buttonMoons{
    border-radius: 20px !important;
    padding: 0.5em 2em !important;
    position: relative;
    @include fontFamily($weight:500);

    .spinner{
        position: absolute;
        top: 25%;
        left: 40%;
    }
}

