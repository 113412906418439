@import './../../../5-styles/config.scss';


.gridContainer{
    @include gridContainer(2, 4em){
      grid-template-columns: 1fr 2fr !important;
    };

     & > span{
        font-size: 1.1em;
     }
}
