@import './../../../5-styles/config.scss';
.wrappRadio{
    & > span:first-child{
        padding: 5px !important;
    }

    & > span:last-child{
        @include fontFamily;
    }
}

.label{
    @include fontFamily($weight:700);
}