@import './../../../5-styles/config.scss';

$paddingContainer: 0.5em;

.cardContainer{
    border: none !important;
    padding: $paddingContainer !important;
}

.cardActionsMultiple{
    justify-content: space-between !important;
    padding: $paddingContainer !important;
    margin: 0.7em 0;
}

.cardActionsSolo{
    justify-content: flex-end !important;
    padding: $paddingContainer !important;
    margin: 0.7em 0;
}

.title{
    font-size: 1.7em;
    border-bottom: 4px solid #FA4974;
    padding: $paddingContainer;
    color: #3f50b5;
    border-bottom-style: double;
    padding-bottom: 0.3em !important;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    @include fontFamily($weight:600);
    @include lt-sm {
        font-size: 1.3em !important;
        text-align: center;
        flex-direction: column;
        align-items: center;
    }

    & > span{
        @include lt-sm {
            margin: 0.5em 0;
        }
    }
}

.heightFull{
    height: calc(100% - 4em);
}