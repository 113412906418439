@import './../../../../5-styles/config.scss';


.StlMethodLabel {
  margin-bottom: 24px;
}

.Title {
  margin-bottom: 16px;
}

.Subtitle {
  margin-bottom: 33px;
}

.barNavigation{
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  @include lt-sm {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  & > div:last-child{
    display: flex;
    align-items: center;
    font-size: 1.3em;
    font-weight: bold;
    
    & > img {
      margin-right: 0.3em;
    }
  }
}


.imgIcon{
  width: 2em;
}

.buttonBack{
  position: absolute;
  left: 0;

  @include lt-sm {
    position: initial !important;
  }
}