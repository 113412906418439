.containerDrop{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    justify-content: center;
    cursor: pointer;
}

.listFilesSucces{
    margin-top: 1em;
    & > li{
        color: rgb(18, 211, 18);
        list-style: inside;
        word-break: break-word;
    }
    

}