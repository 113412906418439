@import './../../../5-styles/config.scss';


.gridContainer{
    @include gridContainer(2, 1em);
    width: 100%;
    height: 100%;
    & > div{
        align-self: flex-start;
        position: relative;
        height: 100%;
        overflow-y: auto;

        @include lt-sm{
            height: auto;
            overflow: initial;
        }

        
    }
}

.onlyValidationPicture{
    grid-template-columns: repeat(1, 1fr) !important;
    
    @include lt-sm{
        margin-bottom: 0 !important;
    }
}