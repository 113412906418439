.navBarContainer{
    position: inherit !important;
    background: white !important;

    h3{
        flex-grow: 1;
    }
}

.userInfo{
    min-width: 200px;
    height: auto;
    display: flex;
    flex-flow: column;
    padding: 0.5em;
    margin-bottom: 5px;
    
    & > div:first-child{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex:1;
        & > span{
            font-size: 4em;
            margin-bottom: 0.1em;
        }
    }

    & > div:last-child{
        display: flex;
        flex-flow: column;
        align-items: center;
        h3{
            font-size: 1.1em;
            text-transform: capitalize;
        }
        span{
            font-size: 0.9em;
            text-transform: lowercase;
            font-style: italic;
        }

    }

    & ~ hr{
        border-bottom: 1px solid #cccc;
        margin-bottom: 5px;
    }
}

.containerLinks{

    h1{
        padding: 0.7em 0.5em;
        border-bottom: 1px solid #cccc;
    }

    .links{
        padding: 0.8em 1em;
        min-width: 15em;
        max-width: 19em;
        color: black;        
        display: flex;
        align-items: center;

        & > span{
            margin-right: 0.3em;
        } 

        &:hover{
            background-color:#efefefcc;
        }
    }
}

.nameBar {
    color: black;
    margin-right: 10px;
    text-transform: capitalize;
    font-size: 1.1em;
    font-weight: bold;
}

.menuLogo {
    object-fit: contain;
    width: 200px;
}
