@import '../../../5-styles/config.scss';

.gridContainer{
    @include gridContainer(1, 2em);
}

[aria-label='severity']{
    @include lt-sm{
        flex-direction: column !important;
    }
}